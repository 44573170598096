<template>
<div class="right-content refund" >
   <div class="dd-title">
        <div
          v-for="(item, index) in ddtitlelist"
          :key="index"
          class="dd-title-item"
        >
          {{ item.text }}
        </div>
    </div>
    <div class="box-shop" v-for="(item, index) in list" :key="index">
          <!-- 编号 -->
          <div class="shop-bianhao">
            <div class="bianhao-num">订单编号:{{ item.RefundCode }}</div>
            <div class="bianhao-time">下单时间:{{ item.CreateTime }}</div>
          </div>
          <!-- 内容 -->
          <div class="shop-content">
            <div class="content-left">
              <!-- 左边每一个 -->
              <div
                class="content-left-item" @click="torefundDetail(item)"
              >
                <div class="content-left-img">
                  <img :src="item.ImgUrl" alt="" />
                </div>
                <div class="content-left-font">
                  <div class="font1">{{ item.GoodsName }}</div>
                  <div class="font2">{{ item.SpecName }}</div>
                </div>
                <div class="content-price">￥{{ item.PayMoney }}</div>
                <div class="content-num">X{{ item.RefundGoodsCount }}</div>
              </div>
              <!-- 截至一个 -->
            </div>
            <div class="content-right">
              <div class="add">
                <div class="add1">
                  <div class="addprice">￥{{ item.ApplyRefundMoney }}</div>
                  <div class="yunfei">（含运费:￥{{item.ApplyPoint}}）</div>
                </div>
              </div>
              <div class="shop-status">
                <div class="status2">{{ item.RefundStatus== 0
                ? "进行中"
                : item.RefundStatus == 1
                ? "已完成"
                : item.RefundStatus == 2
                ? "申请失败"
                : ""}}
            </div>
              </div>
              <div class="oper">
                <div class="oper2">
                  <div  @click="handleCancel(item)" v-if="item.RefundStatus==0">取消售后</div>
                  <div @click="torefundDetail(item)">查看详情</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
</template>
<script>
import { queryApplyRefundList, deleteShopRefund } from "@/api/shop";
export default {
    
    data(){
         return{
            ddtitlelist: [
              { text: "商品" },
              { text: "售价" },
              { text: "数量" },
              { text: "总计" },
              { text: "退款状态" },
              { text: "操作" },
           ],
            list: [],
            page: 1,
            pageSize: 10,
            loading: false,
            finished: false,
            userInfo: {},
            shoplist: [
                {
                id: "202106251632GORDER49212967",
                time: "2021-07-12",
                addprice: 1698,
                shop: [
                    {
                    price: 160,
                    num: 2,
                    src: require("../../../assets/images/shopimg.png"),
                    font: "泉湘阁 玄关装饰画烤瓷手工立体 镶钻竖版过道壁画现代简约进"
                    },
                    {
                    price: 160,
                    num: 2,
                    src: require("../../../assets/images/shopimg.png"),
                    font: "泉湘阁 玄关装饰画烤瓷手工立体 镶钻竖版过道壁画现代简约进"
                    }
                ]
                }
             ], 
         }
    },
    created() {
    if (localStorage.getItem("userInfo") != null) {
            this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
            } else {
            this.userInfo.Id = 0;
        }
    this.init();
  },
  methods:{
        async init() {
            let parm = {
                userId: this.userInfo.Id,
                pageIndex: this.page,
                pageSize: this.pageSize,
            };
            const res = await queryApplyRefundList(parm);
            this.list =res.response.data;
            console.log(this.list,888)
    },
    // 取消
    async handleCancel(obj) {
      const res = await deleteShopRefund(obj.Id);
      if (res.success==true){
        this.$message.success(res.msg);
        this.page = 1;
        this.loading = false;
        this.finished = false;
        this.list=[];
        this.init()
      } else {
        this.$message.error(res.msg);
      }
    },
    //去详情
    torefundDetail(obj){
      console.log(obj,8888)
         this.$router.push({
            path: "/PCshop/goods/refundDetail",
            query: { id: obj.Id,orderid:obj.OrderId },
       });
     }
  }
}
</script>

<style lang='scss' scoped>
.right-content{
    min-height: 500px;
}
   .shop-content {
      border: 1px solid #e9e9e9;
      width: 95%;
      margin: 0 auto;
      display: flex;

      .content-left {
        width: 564px;
        border-right: 1px solid #e9e9e9;
        .content-left-item {
          margin-top: 20px;
          border-right: 1px solid #e9e9e9;
          border-bottom: 1px solid #e9e9e9;
          width: 100%;
          height: 82px;
          display: flex;
          padding-left: 21px;
          .content-left-img {
            width: 60px;
            height: 60px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .content-left-font {
            margin-left: 14px;
            .font1 {
              width: 200px;
              height: 38px;
              /*text-overflow: -o-ellipsis-lastline;*/
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .font2 {
              font-size: 12px;
              color: #999999;
              margin-top:14px;
            }
          }
          .content-price {
            margin-left: 56px;
            margin-top: 8px;
          }
          .content-num {
            margin-left: 48px;
            margin-top: 8px;
          }
        }
      }
      .content-right {
        flex: 1;
        display: flex;
        .add,
        .shop-status,
        .oper {
          width: 33.33333%;
          border-right: 1px solid #e9e9e9;
        }
        .oper {
          .oper2 {
            div {
              text-align: center;
            }
            div:first-child {
              width: 80px;
              height: 30px;
              background: #f95e2e;
              opacity: 1;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              opacity: 1;
              text-align: center;
              line-height: 30px;
              color: #fff;
              margin-bottom: 15px;
            }
          }
        }
        .add {
          display: flex;
          justify-content: center;
          align-items: center;
          .add1 {
            width: 100px;
            height: 50px;
            text-align: center;
          }
        }
        .shop-status,
        .oper {
          display: flex;
          justify-content: center;
          align-items: center;
          .status2 {
            width: 90px;
            height: 35px;
            text-align: center;
          }
        }
      }
    }
    .shop-bianhao{
      display: flex;
      width: 95%;
      margin: 20px auto 0px;
      background: #e9e9e9;
      height: 40px;
      color: #999999;
      font-size: 12px;
      padding-top: 12px;
      padding-left: 10px;
      .bianhao-time {
        margin-left: 50px;
      }
    }
   .dd-title {
    display: flex;
    width: 100%;
    margin-top: 20px;
    background: #e9e9e9;
    height: 40px;
    .dd-title-item {
      line-height: 40px;
      font-weight: 700;
    }
    .dd-title-item:nth-child(1) {
      margin-left: 134px;
    }
    .dd-title-item:nth-child(2) {
      margin-left: 220px;
    }
    .dd-title-item:nth-child(3) {
      margin-left: 39px;
    }
    .dd-title-item:nth-child(4) {
      margin-left:154px;
    }
    .dd-title-item:nth-child(5) {
      margin-left: 72px;
    }
    .dd-title-item:nth-child(6) {
      margin-left: 63px;
    }
  }
</style>