<template>
  <div class="bgc_f5f5f5">
    <Topbar></Topbar>
    <SuS></SuS>
    <div class="my-content">
      <!-- 左侧列表 -->
      <div class="left-list">
        <el-collapse
          v-model="activeNames"
          @change="handleChange(activeNames)"
          accordion
        >
          <div v-for="(item, index) in leftlist" :key="index">
            <div
              v-if="index != 6"
              :class="[
                ischeckIndex === index ? 'left-list-item2' : ' left-list-item',
              ]"
              @click="changetop2(index, leftlist)"
            >
              {{ item.text }}
            </div>
            <el-collapse-item
              style="padding-left: 0"
              v-else
              :title="item.text"
              name="6"
              :class="[
                ischeckIndex === 6 ? 'left-list-item2' : ' left-list-item',
              ]"
            >
              <div
                :class="[item.ischeck ? 'son2' : ' son']"
                v-for="(item, index) in sonlist"
                :key="index"
                @click="son(item.text, sonlist, index)"
              >
                {{ item.text }}
              </div>
            </el-collapse-item>
          </div>
        </el-collapse>
      </div>

      <!-- 右侧内容 -->
      <div class="right-content" v-if="order">
        <!-- 订单标题 -->
        <div class="dd-top">
          <div
            :class="[item.ischeck ? ' dd-top-item2' : ' dd-top-item']"
            v-for="(item, index) in ddlist"
            :key="index"
            @click="changetop(index, ddlist, item)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="dd-title">
          <div
            v-for="(item, index) in ddtitlelist"
            :key="index"
            class="dd-title-item"
          >
            {{ item.text }}
          </div>
        </div>
        <All :orderList="orderList" :total="total" ref="all"></All>
        <!-- 商品详情 -->

        <!--<div v-if="isyonghu">1</div>-->
      </div>
      <!-- 优惠券 -->
      <div class="right-content" v-if="sale">
        <div class="sale-top dd">
          <div class="dd-top">
            <div
              :class="[status == index ? 'dd-top-item2' : 'dd-top-item']"
              v-for="(item, index) in saletypetypes"
              :key="index"
              @click="couponchangetop(index, saletypelist)"
            >
              {{ item.text }}
            </div>
          </div>
          <div class="coupon-box">
            <!-- 可使用 -->
            <div :class="[status == index ? 'dd-top-item2' : 'dd-top-item']">
              <!-- {{ item.text }} -->
              <div
                class="coupon-item"
                :class="status == 1 ? 'gray' : ''"
                v-for="(couponItem, j) in saletypelist"
                :key="j"
              >
                <div class="dot"></div>
                <div class="coupon-item-box">
                  <div class="coupon-top">
                    <p><span>￥</span>{{ couponItem.DiscountMoney }}</p>
                    <p>满{{ couponItem.FullMoney }}元可用</p>
                  </div>
                  <div class="coupon-bottom">
                    <p>适用范围：{{ couponItem.GoodsName }}</p>
                    <p>使用时间：{{ couponItem.EndTime }}</p>
                    <div class="use-now">立即使用</div>
                  </div>
                </div>
                <div class="dot"></div>
              </div>
              <div v-if="saletypelist.length <= 0">
                <nocontent name="暂无优惠卷"></nocontent>
              </div>
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
      <!-- 积分-->
      <div class="right-content" v-if="jifen">
        <div class="dd">
          <div class="dd-top">
            <div
              :class="[item.ischeck ? 'dd-top-item2' : 'dd-top-item']"
              v-for="(item, index) in jifenList"
              :key="index"
              @click="jifenchangetop(index, jifenList)"
            >
              {{ item.text }}
            </div>
          </div>
          <div class="jifen-main">
            <div class="jifen-box">
              <div class="jifen-top" v-if="jifenList[0].ischeck">
                <div>可用积分</div>
                <div>{{ userInfo.Point ? userInfo.Point : 0 }}</div>
              </div>
              <div class="jifen-detailed" v-else>
                <!-- 订单标题 -->
                <div class="dd-title">
                  <div
                    v-for="(item, index) in ddjifentitlelist"
                    :key="index"
                    class=""
                  >
                    {{ item.text }}
                  </div>
                </div>
                <div class="box-shop">
                  <div
                    class="content-left-item"
                    v-for="(item, index) in jifendetailed"
                    :key="index"
                  >
                    <!-- <div class="content-left-img">
                      <img :src="item.src" alt="" />
                    </div> -->
                    <div class="content-left-font">
                      <div class="font1">{{ item.SourceStr }}</div>
                      <!-- <div class="font1">{{ item.SourceStr }}</div> -->
                      <!-- <div class="bianhao-num">编号:{{ item.Remark }}</div> -->
                    </div>

                    <div :class="item.Category == 1 ? 'shouru' : 'zhichu'">
                      {{ item.Category == 1 ? "+" : "-" }}{{ item.Point }}
                    </div>
                    <div class="date">{{ item.CreateTime }}</div>
                    <div class="beizhu">{{ item.Remark }}</div>
                  </div>
                </div>
              </div>
              <div class="clearance"></div>
              <div class="jifen-guize">
                <span class="title">使用规则</span>
                <p>
                  a) 积分专属邮乐,仅限邮乐网内使用;<br />
                  b) 积分可以累积,2年内有效;<br />
                  c)
                  买家在邮乐网消费购物可以获得积分,积分返点比例为:除3C产品外的所有商品均以2元:1积分计,3C产品以10元:1积分计,购买的商品页面中显示
                  积分为零的商品不享受积分获取;<br />
                  d)
                  积分的数值精确到个位(小数点后全部舍弃,不进行四舍五入),例如:**101元的商品,返50(101*0.5=50.5)个积分;
                  <br />
                  e)
                  买家在完成该笔交易(订单状态为“已签收”)后才能得到此笔交易的相应积分,如购买商品参加店铺其他优惠,则优惠的金额部分不享受积分获取;
                  <br />
                  f)
                  买家在邮乐购物付款时可以使用积分,100积分抵扣1元。使用时最多可抵扣应付订单总额的30%或200元人民币,这两个条件以先达到的为准进行
                  抵扣;
                </p>
              </div>
              <div class="jifen-change">
                <div class="jifen-top-title">
                  <span>积分换好礼</span>
                  <div class="fenye">
                    <div>{{ integralpageIndex }}/{{ integraltotal }}</div>
                    <div
                      :class="[integralpageIndex > 1 ? 'canChoose' : '']"
                      @click="changeintegral(1)"
                    >
                      <i class="el-icon-arrow-left"></i>
                    </div>
                    <div
                      :class="[
                        integralpageIndex < integraltotal ? 'canChoose' : '',
                      ]"
                      @click="changeintegral(2)"
                    >
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div class="jifen-product-box">
                  <div
                    class="product-item"
                    v-for="item in integralgoodsList"
                    :key="item.Id"
                    @click="gotoGoodsdetai(item)"
                  >
                    <img
                      :src="item.CoverImg"
                      alt=""
                      style="width: 140px; height: 140px"
                    />
                    <p
                      class="textOver"
                      style="
                        line-height: 20px;
                        font-size: 12px;
                        font-weight: 700;
                        margin-top: 2px;
                      "
                    >
                      {{ item.GoodsName }}
                    </p>
                    <div class="hot_conntent_div_money">
                      <span>{{ item.DefaultPoints }}</span
                      ><span>积分</span><span>＋</span><span>￥</span
                      ><span>{{ item.DefaultPrice }}</span
                      ><span>￥{{ item.DefaultOriginalPrice }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 收货地址-->
      <div class="right-content" v-if="address">
        <div class="address">
          <!-- <div class="title">已保存收货地址 <span>(共一条)</span></div> -->
          <div class="address-box">
            <div class="dd-title">
              <div class="dd-title-item">收货人</div>
              <div class="dd-title-item">收货地址</div>
              <div class="dd-title-item">联系电话</div>
              <div class="dd-title-item">操作</div>
            </div>
            <div class="address-content">
              <div
                class="address-item"
                v-for="item in addressList"
                :key="item.Id"
              >
                <div class="name">{{ item.ContactName }}</div>
                <div class="add">
                  {{ item.Province }}{{ item.City }}{{ item.Area
                  }}{{ item.Address }}
                </div>
                <div class="tel">{{ item.ContactPhone }}</div>
                <div class="operation-btn">
                  <div @click="editAdrs(item)">修改</div>
                  <div>|</div>
                  <div @click="deleteAdrs(item.Id)">删除</div>
                </div>
                <div class="set-address" v-if="item.IsDefault == 1">
                  默认地址
                </div>
                <div
                  class="set-address"
                  v-else
                  style="color: #ccc; border-color: #ccc"
                  @click="defaultAdrs(item)"
                >
                  设为默认地址
                </div>
              </div>
            </div>
            <div class="added">
              <div class="title">
                {{ is_edit_adrs ? "修改收货地址" : "新增收货地址" }}
              </div>
              <el-form
                :model="ruleForm"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="所在地区" prop="">
                  <el-cascader
                    size="large"
                    :options="options"
                    v-model="selectedOptions"
                    @change="handleChangeAdrs"
                    style="width: 300px"
                  >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="">
                  <el-input
                    v-model="ruleForm.adress"
                    placeholder="无需重复填写省市区，小于75个字"
                    type="textarea"
                    class="detail-address"
                    style="max-width: 460px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="收货人姓名" prop="">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="不能为昵称、X先生、X小姐等，请使用真实姓名"
                    style="width: 460px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="">
                  <el-input
                    v-model="ruleForm.phone"
                    placeholder="请填写"
                    style="width: 460px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="默认地址" prop="">
                  <el-radio v-model="ruleForm.radio" label="1">是</el-radio>
                  <el-radio v-model="ruleForm.radio" label="2">否</el-radio>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    style="background: #f95e2e"
                    @click="submitForm"
                    >保存地址</el-button
                  >
                  <el-button type="info" @click="addAdrs" v-if="is_edit_adrs"
                    >新增地址</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <!-- 退款管理 -->
      <div>
        <div class="right-content refund" v-if="refund">
          <After :ddtitlelist="ddtitlelist"></After>
        </div>
      </div>
      <!-- <div class="right-content help" v-if="help">退款管理</div> -->

      <div class="right-content" v-if="mode">
        <div class="mode">
          <div class="title">配送方式</div>
          <div v-html="peisong"></div>
        </div>
      </div>
      <div class="right-content" v-if="service">
        <div class="service">
          <div class="title">售后服务</div>
          <div v-html="shouou"></div>
        </div>
      </div>
      <div class="right-content" v-if="payment">
        <div class="payment">
          <div class="title">支付问题</div>
          <div v-html="zhifu"></div>
        </div>
      </div>
      <div class="right-content" v-if="agreement">
        <div class="agreement">
          <div class="title">用户协议</div>
          <div v-html="yonghu"></div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <Foter></Foter>
  </div>
</template>
<script>
import Topbar from "../../PCshop/components/topbar.vue";
import Foter from "../../PCshop/components/shopfooter.vue";
import SuS from "../../PCshop/components/suspen.vue"; //悬浮
import All from "../../PCshop/components/allorder.vue";
import After from "../../PCshop/components/afterSales.vue"; //退款
import nocontent from "../../shop/components/nocontent.vue"
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import {
  queryMyCouponList,
  queryUserAddress,
  editUserAddress,
  deleteUserAddress,
  queryPointList,
  qeryShopGoodsList,
  queryShopOrderList,
  queryEvaluationList,
  queryHelpCenter
} from "@/api/shop";
export default {
  components: {
    Topbar,
    Foter,
    SuS,
    All,
    After,
    nocontent
  },
  data () {
    return {
      index: 0,
      pageSize: 10,
      total: 0,//订单总数
      ruleForm: {
        adressCode: [],
        name: "",
        adress: "",
        phone: "",
        radio: "2",
      },
      rules: {
        name: [
          { required: true, message: "所在地区", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [{ required: true, message: "所在地区", trigger: "change" }],
      },
      activeNames: ["0"],
      address: false, //收货地址
      refund: false, //退款管理
      help: false, //帮助中心
      mode: false, //配送方式
      service: false, //售后服务
      payment: false, //支付方式
      agreement: false, //用户协议
      zhifu: false,
      jifen: false,
      dd: true,
      order: true, //默认为订单
      sale: false, //优惠
      ddlist: [
        { name: "所有订单", ischeck: true, value: "" },
        { name: "待付款", ischeck: false, value: "0" },
        { name: "待发货", ischeck: false, value: "1" },
        { name: "待收货", ischeck: false, value: "2" },
        { name: "待评价", ischeck: false, value: "3" },
      ],
      ddtitlelist: [
        { text: "商品" },
        { text: "售价" },
        { text: "数量" },
        { text: "总计" },
        { text: "订单状态" },
        { text: "操作" },
      ],

      jifendetailed: [
        {
          time: "2021-07-12",
          income: +1698,
          tag: "true",
          price: 160,
          num: 2,
          src: require("../../../assets/images/shopimg.png"),
          font: "泉湘阁 玄关装饰画烤瓷手工立体 镶钻竖版过道壁画现代简约进",
          number: "202106251632GORDER49212967",
          beizhu: "交易获得",
        },
        {
          time: "2021-07-12",
          income: -1698,
          tag: "false",
          price: 160,
          num: 2,
          src: require("../../../assets/images/shopimg.png"),
          font: "泉湘阁 玄关装饰画烤瓷手工立体 镶钻竖版过道壁画现代简约进",
          number: "202106251632GORDER49212967",
          beizhu: "交易获得",
        },
      ],
      leftlist: [
        { text: "我的订单", ischeck: true },
        { text: "我的优惠券", ischeck: false },
        { text: "我的积分", ischeck: false },
        { text: "收货地址", ischeck: false },
        { text: "退款管理", ischeck: false },
        { text: "课程中心", ischeck: false },
        { text: "帮助中心", ischeck: false },
      ],
      ischeckIndex: 0,
      sonlist: [
        { text: "配送方式", ischeck: true },
        { text: "售后服务", ischeck: false },
        { text: "支付方式", ischeck: false },
        { text: "用户协议", ischeck: false },
      ],
      saletypelist: [],
      saletypetypes: [
        {
          text: "可使用",
          ischeck: true,
          // couponBox: ["1满三十减20", "满三十减10", "满五十减20", "满三十减10"],
        },
        {
          text: "已使用",
          ischeck: false,
          // couponBox: ["2满三十减20", "满三十减10", "满五十减20"],
        },
        {
          text: "已失效",
          ischeck: false,
          // couponBox: ["3满三十减20", "满三十减10", "满五十减20", "满五十减20"],
        },
      ],
      jifenList: [
        { text: "我的积分", ischeck: true },
        { text: "积分明细", ischeck: false },
      ],
      ddjifentitlelist: [
        { text: "来源/用途" },
        { text: "收入/支出" },
        { text: "日期" },
        { text: "备注" },
      ],
      status: 0, //优惠券状态
      addressList: [], //收货地址列表
      options: regionData,
      selectedOptions: [],
      is_edit_adrs: false, //修改还是新增  地址
      adrs_Id: "", //地址修改id
      integralgoodsList: [], //积分兑换商品列表
      integralpageIndex: 1, //积分兑换商品当前页
      integraltotal: 1, //积分兑换商品总页数
      orderList: [], //我的订单数据
      pinglunList: [], //评论列表
      userInfo: {},
      // 帮助中心
      yonghu: '',
      zhifu: '',
      shouou: '',
      peisong: ''
    };
  },
  created () {
    this.common.users();
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }
    if (this.$route.query.index == 3) {
      this.changetop2(3, this.leftlist)
      this.order = false;
      this.sale = false;
      this.jifen = false;
      this.address = true;
      this.refund = false;
      this.mode = false;
      this.service = false;
      this.payment = false;
      this.agreement = false;
    }
    //   {
    //   this.changetop2(1,this.leftlist)
    //       this.order = false;
    //       this.sale = true;
    //       this.jifen = false;
    //       this.address = false;
    //       this.refund = false;
    //       this.mode = false;
    //       this.service = false;
    //       this.payment = false;
    //       this.agreement = false;
    //  }
  },
  mounted () {
    this.queryMyCouponList();
    this.queryUserAddress();
    this.postQueryPointList();
    this.postQeryShopGoodsList();
    this.postQueryShopOrderList();
    this.getqueryHelpCenter();//帮助中心
  },
  methods: {
    //请求帮助中心
    async getqueryHelpCenter () {
      const res = await queryHelpCenter();
      console.log(res, '帮助中心');
      this.yonghu = res.response.UserAgreement;
      this.zhifu = res.response.PayQuestion;
      this.shouou = res.response.AfterSale;
      this.peisong = res.response.DeliveryMethod
    },
    //请求我的订单数据
    async postQueryShopOrderList (val) {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.UserId = this.userInfo.Id;
      parm.Status = val != "" ? val : "";
      const res = await queryShopOrderList(parm);
      console.log(res.response, 5555555555)
      this.total = res.response.dataCount
      this.orderList = res.response.data;
      console.log(this.orderList, "我的订单");
      this.isLoad = false;
      if (res.response.data.length == 0) {
        this.isEnd = true;
      }
    },
    // 待评价列表数据
    async postQueryEvaluationList () {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.UserId = this.userInfo.Id;
      parm.IsEvaluation = 0;
      parm.Status = 3;
      const res = await queryEvaluationList(parm);
      this.orderList = res.response.data;
      this.isLoad = false;
      if (res.response.data.length == 0) {
        this.isEnd = true;
      }
    },

    //前去积分商城详情
    gotoGoodsdetai (item) {
      console.log("前去积分商城详情,可能会需要更改");
      this.$router.push({
        path: "/PCshop/goods/goodsdetail?id=" + item.Id,
      });
    },
    //分页查询按钮上一页下一页
    changeintegral (num) {
      if (num == 1) {
        this.integralpageIndex = this.integralpageIndex - 1;
      } else {
        this.integralpageIndex = this.integralpageIndex + 1;
      }
      this.postQeryShopGoodsList();
    },
    // 积分兑换列表数据
    async postQeryShopGoodsList () {
      let parm = {
        IsShelf: 1,
        minPoints: 1,
        pageIndex: this.integralpageIndex,
        pageSize: 5,
        sortField: "Sort",
        sortType: "asc",
      };
      const res = await qeryShopGoodsList(parm);
      this.integralgoodsList = res.data;
      this.integraltotal = res.pageCount;
    },
    //积分列表
    async postQueryPointList () {
      let parm = {};
      parm.pageIndex = 1;
      parm.pageSize = 1000;
      parm.UserId = JSON.parse(localStorage.getItem("userInfo")).Id;
      const res = await queryPointList(parm);
      this.jifendetailed = res.data;
    },
    //在点击修改之后 突然想新增地址
    addAdrs () {
      this.clearadrsInfo();
    },
    //清除输入的地址信息
    clearadrsInfo () {
      this.ruleForm = {
        adressCode: [],
        name: "",
        adress: "",
        phone: "",
        radio: "2",
      };
      this.adrs_Id = "";
      this.selectedOptions = [];
      this.is_edit_adrs = false;
    },
    //设为默认地址
    defaultAdrs (item) {
      this.adrs_Id = item.Id;
      this.ruleForm = {
        adressCode: [
          TextToCode[item.Province].code + "",
          TextToCode[item.Province][item.City].code + "",
          TextToCode[item.Province][item.City][item.Area].code + "",
        ],
        name: item.ContactName,
        adress: item.Address,
        phone: item.ContactPhone,
        radio: 1,
      };
      this.selectedOptions = this.ruleForm.adressCode;
      this.submitForm();
    },
    //修改地址
    editAdrs (item) {
      this.is_edit_adrs = true;
      this.adrs_Id = item.Id;
      if (item.City === '北京市' || item.City === '天津市' || item.City === '重庆市' || item.City === '上海市') {
        item.City = '市辖区'
      }
      this.ruleForm = {
        adressCode: [
          TextToCode[item.Province].code + "",
          TextToCode[item.Province][item.City].code + "",
          TextToCode[item.Province][item.City][item.Area].code + "",
        ],
        name: item.ContactName,
        adress: item.Address,
        phone: item.ContactPhone,
        radio: item.IsDefault == 1 ? "1" : "2",
      };
      this.selectedOptions = this.ruleForm.adressCode;
    },
    deleteAdrs (Id) {
      this.$confirm("是否删除该地址？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.deleteAddress(Id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //删除地址
    async deleteAddress (Id) {
      const res = await deleteUserAddress(Id);
      if (res.success == true) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.queryUserAddress();
      } else {
        this.$message.error("删除失败");
      }
    },
    //保存地址
    async submitForm () {
      let telStr = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
      if (this.ruleForm.adressCode == '') {
        this.$message({
          message: "请选择省市区",
          type: "warning",
        });
        return;
      }
      if (this.ruleForm.adress == '') {
        this.$message({
          message: "请输入详细地址",
          type: "warning",
        });
        return;
      }
      if (this.ruleForm.name == "") {
        this.$message({
          message: "请输入姓名",
          type: "warning",
        });
        return;
      }
      if (this.ruleForm.phone == '') {
        this.$message({
          message: "请输入11位手机号",
          type: "warning",
        });
        return;
      }
      if (!(telStr.test(this.ruleForm.phone)) && this.ruleForm.phone.length != 11) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning"
        });
        return
      }
      let params = {};
      params.Id = this.adrs_Id || 0;
      params.UserId = JSON.parse(localStorage.getItem("userInfo")).Id;
      params.Province = CodeToText[this.ruleForm.adressCode[0]];
      params.City = CodeToText[this.ruleForm.adressCode[1]];
      params.Area = CodeToText[this.ruleForm.adressCode[2]];
      params.ZipCode = "";
      params.ContactName = this.ruleForm.name;
      params.ContactPhone = this.ruleForm.phone;
      params.Address = this.ruleForm.adress;
      params.AreaCode = this.ruleForm.adressCode[2];
      params.IsDefault = this.ruleForm.radio == 1 ? "1" : "0";
      const res = await editUserAddress(params);
      if (res.success == true) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.clearadrsInfo();
        this.queryUserAddress();
      } else {
        this.$message.error("保存失败");
      }
    },
    //省市区选择
    handleChangeAdrs (value) {
      this.ruleForm.adressCode = value;
    },
    //收货地址列表
    async queryUserAddress () {
      let params = {};
      params.pageIndex = 1;
      params.pageSize = 1000;
      params.userId = JSON.parse(localStorage.getItem("userInfo")).Id;
      const res = await queryUserAddress(params);
      this.addressList = res.response.data;
    },
    //优惠券列表
    async queryMyCouponList () {
      let params = {};
      params.UserId = JSON.parse(localStorage.getItem("userInfo")).Id;
      params.pageIndex = 1;
      params.pageSize = 1000;
      params.Status = this.status;
      let res = await queryMyCouponList(params);
      // this.saletypelist[this.status].couponBox = res.response.data;
      this.saletypelist = res.response.data;
    },
    handleChange (val) {
      this.ischeckIndex = val
      // console.log(val, '')
      if (val == 6) {
        // this.leftlist.map(item => {
        //     item.ischeck = false;
        // })
        // this.sale=false;
        // this.order=false;
        // this.sale=false;
        // this.jifen=false;
        // this.address = false;
        // this.refund = false;
        // this.mode = false;
        // this.service = false;
        // this.payment = false;
        // this.agreement = false;
      }
    },
    son (name, arr, index) {
      // console.log(arr);
      arr = arr.map((item) => {
        item.ischeck = false;
        return item;
      });
      this.$set(arr[index], "ischeck", "true");
      if (name == "配送方式") {
        this.mode = true;
        this.service = false;
        this.payment = false;
        this.agreement = false;
        this.order = false;
        this.sale = false;
        this.jifen = false;
        this.address = false;
        this.refund = false;
      }
      if (name == "售后服务") {
        this.mode = false;
        this.service = true;
        this.payment = false;
        this.agreement = false;
        this.order = false;
        this.sale = false;
        this.jifen = false;
        this.address = false;
        this.refund = false;
      }
      if (name == "支付方式") {
        this.mode = false;
        this.service = false;
        this.payment = true;
        this.agreement = false;
        this.order = false;
        this.sale = false;
        this.jifen = false;
        this.address = false;
        this.refund = false;
      }
      if (name == "用户协议") {
        this.mode = false;
        this.service = false;
        this.payment = false;
        this.agreement = true;
        this.order = false;
        this.sale = false;
        this.jifen = false;
        this.address = false;
        this.refund = false;
      }
    },
    //   点击我的订单
    select (num) {
      if (num == 0) {
        console.log(num);
        this.dd = true;
      }
    },
    //   点击订单分类
    changetop (idx, arr, itm) {
      arr = arr.map((item) => {
        item.ischeck = false;
        return item;
      });
      this.$set(arr[idx], "ischeck", "true");
      this.orderList = [];
      this.pageIndex = 1;
      this.isEnd = false;
      //如果点击的是待评价
      if (itm.value == "3") {
        this.$refs.all.a = false;
        this.postQueryEvaluationList();
      } else {
        this.$refs.all.a = true;
        this.postQueryShopOrderList(itm.value);
      }
    },
    //点击左侧分类
    changetop2 (idx, arr) {
      // arr = arr.map((item) => {
      //   item.ischeck = false;
      //   return item;
      // });
      // console.log(idx, '')
      this.activeNames = idx
      this.ischeckIndex = idx
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.$set(arr[idx], "ischeck", "true");
      if (arr[0].name == "所有订单") {
        return;
      } else {
        if (idx == 0) {
          this.postQueryShopOrderList();
          this.sale = false;
          this.order = true;
          this.sale = false;
          this.jifen = false;
          this.address = false;
          this.refund = false;
          this.mode = false;
          this.service = false;
          this.payment = false;
          this.agreement = false;
        }
        if (idx == 1) {
          this.order = false;
          this.sale = true;
          this.jifen = false;
          this.address = false;
          this.refund = false;
          this.mode = false;
          this.service = false;
          this.payment = false;
          this.agreement = false;
        }
        if (idx == 2) {
          this.order = false;
          this.sale = false;
          this.jifen = true;
          this.address = false;
          this.refund = false;
          this.mode = false;
          this.service = false;
          this.payment = false;
          this.agreement = false;
        }
        if (idx == 3) {
          this.order = false;
          this.sale = false;
          this.jifen = false;
          this.address = true;
          this.refund = false;
          this.mode = false;
          this.service = false;
          this.payment = false;
          this.agreement = false;
        }
        if (idx == 4) {
          // console.log(1);
          this.order = false;
          this.sale = false;
          this.jifen = false;
          this.address = false;
          this.refund = true;
          this.mode = false;
          this.service = false;
          this.payment = false;
          this.agreement = false;
        }
        if (idx == 5) {
          this.$router.push("/personal/myCourse");
        }
        if (idx == 6) {
          this.order = false;
          this.sale = false;
          this.jifen = false;
          this.address = false;
          this.refund = false;
          this.help = true;
          this.mode = false;
          this.service = false;
          this.payment = false;
          this.agreement = false;
        }
      }
    },
    //点击优惠券
    couponchangetop (idx, arr) {
      // arr = arr.map((item) => {
      //   item.ischeck = false;
      //   return item;
      // });
      // this.$set(arr[idx], "ischeck", "true");
      this.saletypelist = []
      this.status = idx;
      this.queryMyCouponList();
    },
    //点击积分
    jifenchangetop (idx, arr) {
      arr = arr.map((item) => {
        item.ischeck = false;
        return item;
      });
      this.$set(arr[idx], "ischeck", "true");
    },
    onSubmit () {
      console.log("submit!");
    },
    // handleClose() {
    //   console.log(1);
    // },
    //帮助中心
  },
};
</script>
<style lang="scss" scoped>
.my-content {
  width: 1200px;
  margin: 20px auto 0px;
  display: flex;
}
.left-list {
  width: 140px;
}
/deep/.el-collapse-item__header {
  border: none !important;
}
/deep/.el-collapse-item__header {
  font-size: 14px;
  color: #333333;
  font-weight: Bold;
  padding-left: 28px;
}
.son {
  width: 100%;
  height: 52px;
  font-size: 14px;
  padding-left: 28px;
  padding-top: 20px;
  cursor: pointer;
}
.son2 {
  width: 100%;
  height: 52px;
  font-size: 14px;
  padding-left: 28px;
  padding-top: 20px;
  color: #f95e2e;
}

//    右侧
.right-content {
  min-height: 410px;
  width: 940px;
  background: #ffffff;
  margin-left: 20px;
  padding-bottom: 28px;
  margin-bottom: 30px;
  .dd-top {
    border-bottom: 1px solid #f5f5f5;
    margin-left: 32px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    height: 60px;
    // width: 60%;
    padding-right: 47%;
    .dd-top-item {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      cursor: pointer;
    }
    .dd-top-item2 {
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      color: #f95e2e;
      border-bottom: 3px solid #f95e2e;
    }
  }
  .dd-title {
    display: flex;
    width: 100%;
    margin-top: 20px;
    background: #e9e9e9;
    height: 40px;
    .dd-title-item {
      line-height: 40px;
      font-weight: 700;
    }
    .dd-title-item:nth-child(1) {
      margin-left: 134px;
    }
    .dd-title-item:nth-child(2) {
      margin-left: 195px;
    }
    .dd-title-item:nth-child(3) {
      margin-left: 39px;
    }
    .dd-title-item:nth-child(4) {
      margin-left: 174px;
    }
    .dd-title-item:nth-child(5) {
      margin-left: 72px;
    }
    .dd-title-item:nth-child(6) {
      margin-left: 63px;
    }
  }

  // 优惠券

  .box-shop {
    .shop-bianhao {
      display: flex;
      width: 95%;
      margin: 20px auto 0px;
      background: #e9e9e9;
      height: 40px;
      color: #999999;
      font-size: 12px;
      padding-top: 12px;
      padding-left: 10px;
      .bianhao-time {
        margin-left: 50px;
      }
    }
    .shop-content {
      border: 1px solid #e9e9e9;
      width: 95%;
      margin: 0 auto;
      display: flex;

      .content-left {
        width: 564px;
        border-right: 1px solid #e9e9e9;
        .content-left-item {
          margin-top: 20px;
          border-right: 1px solid #e9e9e9;
          border-bottom: 1px solid #e9e9e9;
          width: 100%;
          height: 82px;
          display: flex;
          padding-left: 21px;
          .content-left-img {
            width: 60px;
            height: 60px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .content-left-font {
            margin-left: 14px;
            .font1 {
              width: 200px;
              height: 38px;
              /*text-overflow: -o-ellipsis-lastline;*/
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .font2 {
              font-size: 12px;
              color: #999999;
              margin-top: 14px;
            }
          }
          .content-price {
            margin-left: 56px;
            margin-top: 8px;
          }
          .content-num {
            margin-left: 48px;
            margin-top: 8px;
          }
        }
      }
      .content-right {
        flex: 1;
        display: flex;
        .add,
        .shop-status,
        .oper {
          width: 33.33333%;
          border-right: 1px solid #e9e9e9;
        }
        .oper {
          .oper2 {
            div {
              text-align: center;
            }
            div:first-child {
              width: 80px;
              height: 30px;
              background: #f95e2e;
              opacity: 1;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              opacity: 1;
              text-align: center;
              line-height: 30px;
              color: #fff;
              margin-bottom: 15px;
            }
          }
        }
        .add {
          display: flex;
          justify-content: center;
          align-items: center;
          .add1 {
            width: 100px;
            height: 50px;
            text-align: center;
          }
        }
        .shop-status,
        .oper {
          display: flex;
          justify-content: center;
          align-items: center;
          .status2 {
            width: 90px;
            height: 35px;
            text-align: center;
          }
        }
      }
    }
  }
}
// 优惠券
.sale-top {
  .coupon-box {
    min-height: 400px;
    padding-left: 20px;
    box-sizing: border-box;
    .coupon-item:nth-child(3n) {
      margin-right: 0;
    }
    .coupon-item,
    .gray {
      width: 30%;
      float: left;
      position: relative;
      margin: 20px 20px 0 0;
      .dot:first-child {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        top: 35%;
        left: -10px;
      }
      .dot:last-child {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #fff;
        position: absolute;
        top: 35%;
        right: -10px;
      }
      .coupon-item-box {
        .coupon-top {
          height: 88px;
          background: linear-gradient(135deg, #fd7234 0%, #f3321f 100%);
          opacity: 1;
          border-radius: 2px;
          padding-top: 15px;
          box-sizing: border-box;
          p:first-child {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 800;
            line-height: 20px;
            color: #ffffff;
            opacity: 1;
            text-align: center;
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 300;
              line-height: 19px;
              color: #ffffff;
              opacity: 1;
            }
          }
          p:nth-child(2) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            line-height: 19px;
            color: #ffffff;
            opacity: 1;
            margin-top: 6px;
            text-align: center;
          }
        }
        .coupon-top.invalid {
          background: #f2f2f2;
          p {
            color: #999;
            span {
              color: #999;
            }
          }
        }
        .coupon-bottom {
          background: #f2f2f2;
          opacity: 1;
          border-radius: 2px;
          padding: 13px 20px 20px 20px;
          box-sizing: border-box;
          p:first-child {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #999999;
            opacity: 1;
          }
          p:nth-child(2) {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #999999;
            opacity: 1;
            margin-top: 12px;
          }
          .use-now {
            width: 53%;
            height: 34px;
            background: #fff1ec;
            border: 1px solid #f95e2e;
            opacity: 1;
            text-align: center;
            line-height: 34px;
            border-radius: 20px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f95e2e;
            margin: 20px auto 0 auto;
          }
        }
      }
    }
    .gray {
      .dot:first-child {
        top: 45%;
      }
      .dot:last-child {
        top: 45%;
      }
      .coupon-item-box {
        .coupon-top {
          background: #f2f2f2 !important;
          border-bottom: 1px solid #d9d0cd;
          p:first-child,
          p:nth-child(2) {
            color: #999 !important;
            span {
              color: #999 !important;
            }
          }
        }
        .coupon-bottom {
          .use-now {
            display: none !important;
          }
        }
      }
    }
  }
}
//积分
.jifen-box {
  .jifen-top {
    width: 620px;
    height: 120px;
    margin: 20px 0 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding-left: 100px;
    background: red;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/jf_bg.png");
    color: #fff;
  }
  .clearance {
    width: 100%;
    height: 30px;
    background: #f5f5f5;
  }
  .jifen-guize {
    padding-left: 20px;
    padding-top: 20px;
    box-sizing: border-box;
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 19px;
      color: #333333;
      opacity: 1;
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 10px;
      display: block;
      box-sizing: border-box;
    }
  }
  .jifen-change {
    .jifen-top-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      background: #f5f5f5;
      align-items: center;
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 19px;
        color: #333333;
        opacity: 1;
        display: block;
        background: #f5f5f5;
      }
    }
    .clearance {
      width: 100%;
      height: 20px;
      display: block;
      background: #f5f5f5;
    }
    .jifen-product-box {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 30px;
      box-sizing: border-box;
      .product-item {
        cursor: pointer;
        width: 16%;
        display: flex;
        flex-direction: column;
        margin-right: 44px;
        img {
          width: 100%;
        }
        p:first-child {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 20px;
          color: #333333;
          opacity: 1;
          margin: 15px 0 10px 0;
        }
        p:last-child {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 20px !important;
          color: #f40707;
          opacity: 1;
        }
      }
      .product-item:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
}
.jifen-detailed {
  width: 96%;
  margin: 20px auto;
  border: 1px solid #e9e9e9;
  border-bottom: none;
  .dd-title {
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    div {
      text-align: center;
    }
    div:first-child {
      width: 15%;
    }
    div:nth-child(2) {
      width: 15%;
    }
    div:nth-child(3) {
      width: 20%;
    }
    div:nth-child(4) {
      width: 50%;
    }
  }
  .box-shop {
    .content-left-item {
      height: 85px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      .content-left-img {
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .content-left-font {
        width: 15%;
        text-align: center;
        .font1 {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .bianhao-num {
          font-size: 12px;
          color: #999999;
          margin-top: 7px;
        }
        .font2 {
          font-size: 12px;
          color: #999999;
          margin-top: 7px;
          height: 36px;
          overflow: hidden;
        }
      }
      .shouru {
        width: 15%;
        text-align: center;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 19px;
        color: #fe3030;
        opacity: 1;
      }
      .zhichu {
        text-align: center;
        width: 15%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 19px;
        color: #08ac0d;
        opacity: 1;
      }
      .date {
        text-align: center;
        width: 20%;
      }
      .beizhu {
        text-align: center;
        width: 50%;
      }
    }
  }
}
.address {
  padding: 20px;
  box-sizing: border-box;
  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 19px;
    color: #333;
    opacity: 1;
    margin-bottom: 24px;
    span {
      color: #999999;
    }
  }
  .address-box {
    .dd-title {
      display: flex;
      align-items: center;
      height: 40px;
      border: 1px solid #e9e9e9;
      border-bottom: none;
      line-height: 40px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      opacity: 1;
      .dd-title-item:nth-child(1) {
        margin-left: 38px;
      }
      .dd-title-item:nth-child(2) {
        margin-left: 102px;
      }
      .dd-title-item:nth-child(3) {
        margin-left: 29%;
      }
      .dd-title-item:nth-child(4) {
        margin-left: 17%;
      }
    }
    .address-content {
      border: 1px solid #e9e9e9;
      opacity: 1;
      border-bottom: none;
      position: relative;
      .address-item {
        display: flex;
        padding-top: 22px;
        padding-left: 38px;
        padding-right: 50px;
        box-sizing: border-box;
        align-items: baseline;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        border-bottom: 1px solid #e9e9e9;
        color: #333333;
        opacity: 1;

        height: 80px;
        .name {
          width: 10%;
          overflow: hidden;
        }
        .add {
          margin-left: 7%;
          width: 31%;
        }
        .tel {
          margin-left: 7%;
        }
        .operation-btn {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #61bcf1;
          opacity: 1;
          margin-left: 13.6%;
          cursor: pointer;
          width: 8%;
          justify-content: space-between;
          align-items: center;
        }
        .set-address {
          width: 80px;
          height: 24px;
          border: 1px solid #f95e2e;
          cursor: pointer;
          border-radius: 20px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #f95e2e;
          opacity: 1;
          text-align: center;
          line-height: 24px;
          //margin-left: 9%;
          right: 20px;
          position: absolute;
        }
      }
    }
    .added {
      .title {
        margin-top: 30px;
      }
      .el-form {
        .region {
          width: 260px;
        }
        .detail-address {
          .el-textarea__inner {
            width: 460px;
            height: 86px;
          }
        }
      }
    }
  }
}
.mode,
.service,
.payment,
.agreement {
  .title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 19px;
    color: #333333;
    opacity: 1;
    margin-bottom: 10px;
    border-bottom: 1px solid #e9e9e9;
    padding: 20px 0 20px 20px;
    box-sizing: border-box;
  }
  span {
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    line-height: 40px;
    color: #333333;
    opacity: 1;
    display: block;
  }
  p {
    padding: 0 20px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    line-height: 40px;
    color: #999;
    opacity: 1;
  }
}

.left-list-item {
  width: 100%;
  height: 48px;
  line-height: 48px;
  background: #ffffff;
  font-weight: Bold;
  padding-left: 28px;
  cursor: pointer;
}
.left-list-item2,
/deep/.el-collapse .el-collapse-item__header.is-active {
  width: 100%;
  height: 48px;
  line-height: 48px;
  background: #f95e2e;
  font-weight: Bold;
  padding-left: 28px;
  color: #ffffff;
}
.hot_conntent_div_money span:nth-child(1) {
  color: #fe4a4a;
  font-weight: bold;
  font-size: 15px;
}

.hot_conntent_div_money span:nth-child(2) {
  color: #fe4a4a;
  font-size: 8px;
}

.hot_conntent_div_money span:nth-child(3) {
  color: #fe4a4a;
  // text-decoration: line-through;
  font-weight: bold;
  font-size: 10px;
}

.hot_conntent_div_money span:nth-child(4) {
  color: #fe4a4a;
  // text-decoration: line-through;
  font-weight: bold;
  font-size: 10px;
}

.hot_conntent_div_money span:nth-child(5) {
  color: #fe4a4a;
  // text-decoration: line-through;
  font-weight: bold;
  font-size: 15px;
}

.hot_conntent_div_money span:nth-child(6) {
  color: #c9c9c9;
  text-decoration: line-through;
  font-weight: bold;
  font-size: 8px;
  margin-left: 10px;
}
.fenye {
  display: flex;
  align-items: center;
  height: 21px;
  div {
    cursor: pointer;
  }
  div:nth-child(1) {
    font-size: 12px;
  }
  div:nth-child(2),
  div:nth-child(3) {
    width: 40px;
    background: #eae3e3;
    height: 21px;
    line-height: 21px;
    color: #b3abab;
    text-align: center;
    border: 1px solid #ccc;
  }
  div:nth-child(2) {
    border-right: 0;
    margin-left: 10px;
  }
  .canChoose {
    background: #fff !important;
  }
}
</style>
