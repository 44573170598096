<template>
  <div>
    <div class="dd" v-if="orderList.length != 0">
      <!-- 商品详情 -->
      <div class="box-shop" v-for="(item, index) in orderList" :key="index">
        <!-- 编号 -->
        <div class="shop-bianhao">
          <div class="bianhao-num">订单编号:{{ item.ShopOrderNumber }}</div>
          <div class="bianhao-time">下单时间:{{ item.PayTime }}</div>
        </div>
        <!-- 内容 -->
        <div class="shop-content">
          <div class="content-left" v-if="a">
            <div
              class="content-left-item"
              v-for="(item, index) in item.OrderDetailList"
              :key="index"
              @click="orderdeail(item, item.Status)"
            >
              <div class="content-left-img">
                <img :src="item.SpecImg" alt="" />
              </div>
              <div class="content-left-font">
                <div class="font1">{{ item.GoodsName }}</div>
                <div class="font2">{{ item.SpecName }}</div>
              </div>
              <div class="content-price">￥{{ item.UnitPrice }}</div>
              <div class="content-num">x{{ item.GoodsCount }}</div>
              <!-- <div class="content-status">{{
                    item.Status == 0
                        ? "待付款"
                        : item.Status == 1
                        ? "待发货"
                        : item.Status == 2
                        ? "待收货"
                        : item.Status == 3
                        ? "交易成功"
                        : item.Status == 4
                        ? "已退款"
                        : "交易成功"
                    }}</div> -->
            </div>
          </div>

          <!-- 这是评价 -->
          <div class="content-left" v-if="!a">
            <div
              class="content-left-item"
              @click="orderdeail(item, item.Status)"
            >
              <div class="content-left-img">
                <img :src="item.SpecImg" alt="" />
              </div>
              <div class="content-left-font">
                <div class="font1">{{ item.GoodsName }}</div>
                <div class="font2">{{ item.SpecName }}</div>
              </div>
              <div class="content-price">￥{{ item.UnitPrice }}</div>
              <div class="content-num">x{{ item.GoodsCount }}</div>
              <!-- <div class="content-status">{{
                    item.Status == 0
                        ? "待付款"
                        : item.Status == 1
                        ? "待发货"
                        : item.Status == 2
                        ? "待收货"
                        : item.Status == 3
                        ? "交易成功"
                        : item.Status == 4
                        ? "已退款"
                        : "交易成功"
              }}</div> -->
            </div>
          </div>
          <div class="content-right">
            <div class="add">
              <div class="add1">
                <div class="addprice">￥{{ item.TotalMoney }}</div>
                <div class="yunfei">（含运费:￥{{ item.Postage }}）</div>
              </div>
            </div>
            <div class="shop-status">
              <div class="status2" v-if="item.Status == 0">待付款</div>
              <div class="status2" v-if="item.Status == 1">
                <div>待发货</div>
                <!-- <div class="see">查看物流</div> -->
              </div>
              <div class="status2" v-if="item.Status == 2">
                <div>待收货</div>
                <!-- <div class="see2">确认收货</div> -->
              </div>
              <div class="status2" v-if="item.Status == 3 && !a">
                <div>待评价</div>
              </div>
              <div class="status2" v-if="item.Status == 3 && a">
                <div>交易成功</div>
              </div>
            </div>
            <div class="oper">
              <div class="oper2" v-if="item.Status == 0">
                <div
                  class="pay"
                  @click="
                    orderdeail(
                      item.OrderDetailList[0],
                      item.OrderDetailList[0].Status
                    )
                  "
                >
                  立即付款
                </div>
                <div
                  class="cancel"
                  @click="
                    deleteOrder(item.OrderDetailList[0].ShopOrderId, index)
                  "
                >
                  取消订单
                </div>
              </div>
              <div class="oper2" v-if="item.Status == 1">
                <!-- <div class="cancel">提醒发货</div> -->
                <div
                  class="over"
                  @click="
                    applyservice(item.OrderDetailList[0].ShopOrderId, index)
                  "
                >
                  退款
                </div>
              </div>
              <div class="oper2" v-if="item.Status == 2">
                <div
                  class="pingjia"
                  @click="
                    orderdeail(
                      item.OrderDetailList[0],
                      item.OrderDetailList[0].Status
                    )
                  "
                >
                  查看物流
                </div>
                <div
                  class="cancel"
                  @click="
                    orderdeail(
                      item.OrderDetailList[0],
                      item.OrderDetailList[0].Status
                    )
                  "
                >
                  确认收货
                </div>
              </div>
              <div
                class="oper2"
                v-if="item.Status == 3 && item.IsEvaluation != 1"
              >
                <div
                  class="cancel"
                  @click="toeva(item.ShopOrderId, item.SpecId)"
                  v-if="!a"
                >
                  评价
                </div>
                <!-- 下面是所有订单的评价 -->
                <!-- <div class="cancel" @click="toeva(item.OrderDetailList[0].ShopOrderId,item.OrderDetailList[0].SpecId)" v-if="a">评价</div>  -->
                <div
                  class="cancel"
                  @click="
                    orderdeail(
                      item.OrderDetailList[0],
                      item.OrderDetailList[0].Status
                    )
                  "
                  v-if="a"
                >
                  详情
                </div>
                <div
                  class="over"
                  @click="
                    applyservice(item.OrderDetailList[0].ShopOrderId, index)
                  "
                  v-if="a"
                >
                  申请售后
                </div>
                <div
                  class="over"
                  @click="applyservice(item.ShopOrderId, index)"
                  v-else
                >
                  申请售后
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dd2" v-if="orderList.length == 0">
      <Nocontent name="暂无订单"></Nocontent>
    </div>
    <el-pagination
      v-if="orderList.length > 0"
      background
      :pager-count="7"
      :page-size="pageSize"
      :current-page.sync="pageIndex"
      layout="prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { Toast, Dialog } from "vant";
import Nocontent from "../../shop/components/nocontent.vue";
import { queryShopLogisticsList, confirmReceipt, shopOrderDelete, queryShopOrderList } from "@/api/shop";
export default {
  props: {
    orderList: { type: Array },
    total: { type: Number }
  },


  components: {
    Nocontent,
  },
  data () {
    return {
      // total: 0, //订单总数
      a: true,
      active: 0,
      isLoad: false,
      isLoading: false,
      isEnd: false,
      pageIndex: 1,
      pageSize: 10,
      userInfo: {},
      evaluationList: [],

    };
  },
  created () {
    console.log(this.a);
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }
  },
  mounted () { },
  methods: {

    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.postQueryShopOrderList();
    },
    //请求我的订单数据
    async postQueryShopOrderList (val) {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.UserId = this.userInfo.Id;
      parm.Status = val != "" ? val : "";
      const res = await queryShopOrderList(parm);
      this.total = res.response.dataCount
      this.orderList = res.response.data;
      // console.log(orderList, "我的订单");
      this.isLoad = false;
      if (res.response.data.length == 0) {
        this.isEnd = true;
      }
    },
    //to详情页
    orderdeail (item, status) {
      console.log(status)
      this.$router.push({
        path: "/PCshop/my/orderdetail?orderId=" + item.ShopOrderId + '&' + 'sts' + '=' + status,
      });
    },
    immediately (item) {
      // console.log(item,'回去付款')
      // console.log(item.OrderDetailList[0].GoodsCount)
      // console.log(item.OrderDetailList[0].GoodsId)
      // console.log(item.OrderDetailList[0].SpecId)
      let goodsData = [];
      let data = {};
      data.GoodsId = item.OrderDetailList[0].GoodsId;
      data.SpecId = item.OrderDetailList[0].SpecId;
      data.Count = item.OrderDetailList[0].GoodsCount;
      goodsData.push(data);
      this.$router.push({
        path: "/PCshop/goods/check",
        query: { goodsData: JSON.stringify(goodsData) },
      });
      // this.$router.push({
      //   path: "/PCshop/goods/check?goodsId=" + item.Id,
      // });
    },
    //去评价页面
    toeva (id, id2) {
      this.$router.push({
        path: "/PCshop/goods/evaluate",
        query: { orderId: id, specId: id2 },
      });
    },
    applyservice (id) {
      console.log(id, 7777)
      this.$router.push({
        path: "/PCshop/service/applyservice",
        query: { orderId: id },
      });
    },
    //确认收货
    confirm (id, index) {
      console.log(id.LogisticsId)
      Dialog.confirm({
        title: "确认收货",
        message: "确定商品已收到",
      })
        .then(() => {
          this.orderList.splice(index, 1);
          this.postConfirmReceipt(id.LogisticsId, index);
        })
        .catch(() => { });
    },
    //确认收货
    async postConfirmReceipt (id, idx) {
      const res = await confirmReceipt(id);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        //确认收货成功之后重新请求列表数据
        this.orderList.splice(idx, 1);
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    //列表数据函数
    async postQueryShopLogisticsList () {
      const res = await queryShopLogisticsList(this.orderId);
      this.orderList = res.response;
    },
    //取消订单事件
    deleteOrder (ShopOrderId, index) {
      Dialog.confirm({
        title: "取消订单",
        message: "确定取消该订单吗",
      })
        .then(() => {
          console.log(ShopOrderId);
          this.postShopOrderDelete(ShopOrderId, index);
        })
        .catch(() => {
          // on cancel
        });
    },
    //取消订单
    async postShopOrderDelete (ShopOrderId, index) {
      const res = await shopOrderDelete(ShopOrderId);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        this.orderList.splice(index, 1);
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },

  }
};
</script>
 <style lang="scss" scoped>
.dd2 {
  min-height: 341px;
}
.dd {
  min-height: 450px;
  .dd-top {
    border-bottom: 1px solid #f5f5f5;
    margin-left: 32px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    height: 60px;
    // width: 60%;
    padding-right: 47%;
    .dd-top-item {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .dd-top-item2 {
      font-size: 16px;
      font-weight: bold;
      color: #f95e2e;
      border-bottom: 3px solid #f95e2e;
    }
  }
  .dd-title {
    display: flex;
    width: 95%;
    margin: 20px auto;
    background: #e9e9e9;
    height: 40px;
    .dd-title-item {
      line-height: 40px;
    }
    .dd-title-item:nth-child(1) {
      margin-left: 134px;
    }
    .dd-title-item:nth-child(2) {
      margin-left: 195px;
    }
    .dd-title-item:nth-child(3) {
      margin-left: 39px;
    }
    .dd-title-item:nth-child(4) {
      margin-left: 33px;
    }
    .dd-title-item:nth-child(5) {
      margin-left: 72px;
    }
    .dd-title-item:nth-child(6) {
      margin-left: 72px;
    }
    .dd-title-item:nth-child(7) {
      margin-left: 65px;
    }
  }
  .box-shop {
    .shop-bianhao {
      display: flex;
      width: 95%;
      margin: 20px auto 0px;
      background: #e9e9e9;
      height: 40px;
      color: #999999;
      font-size: 12px;
      padding-top: 12px;
      padding-left: 10px;
      .bianhao-time {
        margin-left: 50px;
      }
    }
    .shop-content {
      border: 1px solid #e9e9e9;
      width: 95%;
      margin: 0 auto;
      display: flex;

      .content-left {
        width: 564px;
        border-right: 1px solid #e9e9e9;

        .content-left-item {
          cursor: pointer;
          margin-top: 20px;
          border-right: 1px solid #e9e9e9;
          border-bottom: 1px solid #e9e9e9;
          width: 100%;
          height: 82px;
          display: flex;
          padding-left: 21px;
          .content-left-img {
            width: 60px;
            height: 60px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .content-left-font {
            margin-left: 14px;
            .font1 {
              width: 200px;
              height: 38px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .font2 {
              height: 38px;
              overflow: hidden;
              font-size: 12px;
              color: #999999;
              margin-top: 7px;
              width: 200px;
            }
          }
          .content-price {
            margin-left: 30px;
            margin-top: 8px;
          }
          .content-num {
            margin-left: 48px;
            margin-top: 8px;
          }
          .content-status {
            margin-left: 40px;
            margin-top: 8px;
          }
        }
      }
      .content-right {
        flex: 1;
        display: flex;
        .add,
        .shop-status {
          width: 33.33333%;
          border-right: 1px solid #e9e9e9;
        }
        .oper {
          cursor: pointer;
          width: 33.33333%;
        }
        .add {
          display: flex;
          justify-content: center;
          align-items: center;
          .add1 {
            width: 100px;
            height: 50px;
            text-align: center;
          }
        }
        .shop-status,
        .oper {
          display: flex;
          justify-content: center;
          align-items: center;
          .status2 {
            width: 90px;
            height: 35px;
            text-align: center;
            .see {
              color: #51b8f1;
            }
            .see2 {
              color: #333333;
            }
          }
        }
        .oper2 {
          font-size: 12px;
          .pay {
            width: 80px;
            height: 30px;
            background: #f95e2e;
            text-align: center;
            line-height: 30px;
            color: #ffffff;
          }
          .cancel {
            width: 80px;
            height: 30px;
            text-align: center;
            line-height: 30px;
          }
          .over {
            width: 80px;
            height: 30px;
            background: #51b8f1;
            color: #ffffff;
            text-align: center;
            line-height: 30px;
          }
          .pingjia {
            width: 80px;
            height: 30px;
            border: 1px solid #e9e9e9;
            text-align: center;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>